import React from "react";
import Components from '../components/index';
import Layout from '../components/Layout';
import '../components/BaseStyles/main.scss';

const IndexPage = ({pageContext}) => {
  const {bucket, slug, data, infoDate} = pageContext
  const {page} = data
  page.rendering = page.rendering || 'default';

  return (
    <div id="app" data-info-date={infoDate}>
      <Layout data={data}>
        {Components(page, data)}
      </Layout>
    </div>
  )
}

export default IndexPage