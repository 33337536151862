import React , {ReactElement} from 'react'
import DefaultPage from '../templates/DefaultPage'
import {handleError} from '@ilc-technology/cefcom-utils';

// Components

const Components = {
  'default': DefaultPage,
}

interface IBlok {
  _uid?: string,
  rendering?: string,
  page?: {component: string}
}

export default (blok: IBlok, data) => {
  if (blok && typeof Components[blok.rendering] !== 'undefined') {
    return React.createElement(Components[blok.rendering], {content: blok, data})
  }

  return null

}