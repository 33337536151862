import React from 'react'

const Sidebar = ({content}) => {
  return !content ? null : (
    <aside className="col-md-4">
      <section id="sidebar-second" className="sidebar clearfix">
        <div className="region region-sidebar-second">
          <section id="block-block-1" className="block block-block block-adv clearfix clearfix">
            <p>
              <a href="/wwen/english-resources/english-test/">
                <img alt={'ef'} src="https://mediakey1.ef.com/~/media/CentralNS/edufind/test-banner-1.png" />
              </a>
            </p>
          </section>
          <section id="block-views-news-block" className="block block-views clearfix clearfix" dangerouslySetInnerHTML={{__html: content}} />
        </div>
      </section>
    </aside>
  )
}

export default Sidebar