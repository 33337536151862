import React from "react"
import { getNestedVals } from "@ilc-technology/cefcom-utils"
import "./styles.scss"
import {
  LazyResponsiveImage,
  GridContainer,
  GridRow,
  GridCol,
} from "@ilc-technology/cefcom-ui-library"
const FatFooter = ({ content }) => {
  const cards = getNestedVals(content, ["cards"])
  return !content ? null : (
    <section id="fatfooter" className="fatfooter clearfix">
      <GridContainer>
        <GridRow>
          <h2 className="section-title">{content.title}</h2>

          <div className="fatfooter__cards">
            <GridRow>
              {cards.map((card, i) => {
                const {
                  title,
                  anchorText,
                  anchorUrl,
                  description,
                  icons,
                  isDisabled,
                  isExternal,
                } = card
                return (
                  <GridCol classNames={["-s-12", "-m-4", "fatfooter-col"]}>
                    <a
                      href={anchorUrl}
                      target={isExternal ? "_blank" : "_self"}
                      style={{ textDecoration: "none" }}
                      className="link-issue"
                    >
                      <div className="fatfooter__item">
                        <div className="fatfooter__img">
                          {/* <img src={icons[0]} /> */}
                          <LazyResponsiveImage responsiveImages={icons || []} />
                        </div>
                        <div className="fatfooter__block">
                          <h3 className="card-title">{title}</h3>
                          <span
                            href={anchorUrl}
                            target={isExternal ? "_blank" : "_self"}
                            style={{ textDecoration: "none" }}
                            className="link-issue"
                          >
                            <div
                              className="fatfooter__description"
                              dangerouslySetInnerHTML={{ __html: description }}
                            />
                          </span>

                          <span
                            className={`fatfooter__link ${
                              isExternal ? "external-icon" : "default-icon"
                            } `}
                          >
                            {anchorText}
                          </span>
                        </div>
                      </div>
                    </a>
                  </GridCol>
                )
              })}
            </GridRow>
          </div>
        </GridRow>
      </GridContainer>
    </section>
  )
}

export default FatFooter
