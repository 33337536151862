import React from "react"
import { GridContainer } from "@ilc-technology/cefcom-ui-library"
import Sidebar from "../../components/Sidebar"
import FatFooter from "../../components/FatFooter"
import { IPageProps } from "../../../types"
import { getNestedVals } from "@ilc-technology/cefcom-utils"
import "./styles.scss"

export default ({ content, data }: IPageProps) => {
  const { mainContent, pageTitle, sidebarContent, introText } = content
  const isTwoColumnLayout = sidebarContent ? true : false
  const { page } = data
  const cta = getNestedVals(page, ["cta"])
  const bottomCta = getNestedVals(content, ["bottomCta"])
  const topCta = getNestedVals(content, ["topCta"])
  const fatFooter = getNestedVals(page, ["fatFooter"])
  // const mainContent = getNestedVals(page, ['mainContent'])

  let modifiedContent = mainContent

  if (modifiedContent.indexOf("&lt;") >= 0 || modifiedContent.indexOf("&gt;")) {
    // decoding WYSIWYG changes in SB
    modifiedContent = modifiedContent
      .split("&lt;")
      .join("<")
      .split("&gt;")
      .join(">")
  }

  // Removing issues with the data builder adding prefix to /assetscdn/
  const regex = /\/.{1,4}\/assetscdn/g
  if (modifiedContent.match(regex)) {
    modifiedContent = modifiedContent.replace(regex, "/assetscdn")
  }

  return (
    <div className={"node-type"}>
      <div
        id="page"
        className={`${
          isTwoColumnLayout ? "two-col-layout homepage" : ""
        } clearfix`}
      >
        <div id="main-content">
          <GridContainer>
            <div className="row">
              <section className={`col-md-${isTwoColumnLayout ? "8" : "12"}`}>
                {pageTitle && pageTitle.length && (
                  <h1
                    className="page-title"
                    dangerouslySetInnerHTML={{ __html: pageTitle }}
                  />
                )}
                {introText && (
                  <div
                    className="intro-text"
                    dangerouslySetInnerHTML={{ __html: introText }}
                  />
                )}
                {topCta && (
                  <a
                    href={topCta.anchorUrl}
                    className="ef-button -primary -intro-text-cta"
                  >
                    {topCta.anchorText}
                  </a>
                )}

                <div dangerouslySetInnerHTML={{ __html: modifiedContent }} />

                {bottomCta && (
                  <a href={bottomCta.anchorUrl} className="ef-button -primary">
                    {bottomCta.anchorText}
                  </a>
                )}
              </section>

              {isTwoColumnLayout && <Sidebar content={sidebarContent} />}
            </div>
          </GridContainer>
        </div>
        {fatFooter && <FatFooter content={fatFooter} />}
      </div>
    </div>
  )
}
