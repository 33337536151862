import React from "react"
import { Helmet } from "react-helmet"
import { Footer } from "@ilc-technology/cefcom-react-footer"
import { CookieBanner } from "@ilc-technology/cefcom-react-cookie-banner"
import {
  HeaderWrapper,
  TopBar,
  ProductHeader,
  ProductMenu,
  ProductMenuMobile,
  StickyBar,
  StickyBarMobile,
  EOneMarketsPopup,
} from "@ilc-technology/cefcom-react-header"
import { getNestedVals } from "@ilc-technology/cefcom-utils"
import { ClickMap } from "@ilc-technology/cefcom-clickmap"
import { MetaTags } from "@ilc-technology/cefcom-metatags"

export default props => {
  const { data, children } = props
  const {
    header,
    page,
    slug,
    footer,
    app,
    seolinks,
    metatags,
    lastModified,
  } = data

  const mc = getNestedVals(app, ["site", "marketCode"], null)
  const { gtmContainerId, isRtl } = app?.site
  const { cookieBanner } = footer
  const { bannerServiceLabels, showBanner } = cookieBanner || {}
  const theme = getNestedVals(header, ["header", "settings", "theme"], null)
  const contentArea = getNestedVals(metatags, ["contentArea"])
  const contentAreaDetail = getNestedVals(metatags, ["contentAreaDetail"])

  const { hideCTA, hideStickyMenu } = page
  const pageSlug = page?.slug
  const hrefLang = getNestedVals(metatags, ["hrefLang"], "")
  const inPageDb = getNestedVals(metatags, ["inPageDb"], "")
  const fullSlug = getNestedVals(metatags, ["fullSlug"], "")
  const contentDatePage = getNestedVals(lastModified, ["page-storyblok"], null)
  const contentDateHeader = getNestedVals(lastModified, ["header"], null)
  const contentDateFooter = getNestedVals(lastModified, ["footer"], null)
  const contentDateMetatags = getNestedVals(lastModified, ["metatags"], null)
  const contentDateTogglelinks = getNestedVals(
    lastModified,
    ["togglelinks"],
    null
  )
  const contentDateChangecountry = getNestedVals(
    lastModified,
    ["changecountry"],
    null
  )
  const contentDateBreadcrumbs = getNestedVals(
    lastModified,
    ["breadcrumbs"],
    null
  )

  let dataMasterEnglishPagename = fullSlug
  dataMasterEnglishPagename = dataMasterEnglishPagename.replaceAll("/", ":")

  return (
    <div
      className={`site-wrapper ${theme ? theme : ""} cefcom-${pageSlug} ${
        hideCTA ? "cefcom-hide-cta" : ""
      } ${hideStickyMenu ? "cefcom-hide-sticky" : ""}`}
    >
      <Helmet>
        <html
          className={`mkt-${mc.toLowerCase()} ${isRtl ? "rtl" : ""} ${
            !getNestedVals(page, ["page", "displayHeaderShadow"], true)
              ? " -no-header-shadow-page "
              : ""
          }`}
          data-inPageDb={inPageDb}
          lang={hrefLang}
          data-master-english-pagename={dataMasterEnglishPagename}
          data-content-area={contentArea}
          data-content-area-detail={contentAreaDetail}
          data-content-date-page={contentDatePage}
          data-content-date-header={contentDateHeader}
          data-content-date-footer={contentDateFooter}
          data-content-date-metatags={contentDateMetatags}
          data-content-date-togglelinks={contentDateTogglelinks}
          data-content-date-changecountry={contentDateChangecountry}
          data-content-date-breadcrumbs={contentDateBreadcrumbs}
          data-branch-version={process.env.BRANCH}
        />
        <script>
          {`(function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
          'gtm.start':
          new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          '//www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${gtmContainerId}');`}
        </script>
      </Helmet>
      <MetaTags
        metaData={{
          meta: { seoMetaContentResultSet: metatags },
          page: page.page,
        }}
      />
      <ClickMap />
      <HeaderWrapper data={header} withSticky>
        <TopBar data={header} />
        <ProductHeader data={header} />
        <ProductMenu data={header} currentUrl={slug} />
        <ProductMenuMobile data={header} currentUrl={slug} />
        <EOneMarketsPopup data={header} />
        <StickyBar data={header} />
        <StickyBarMobile data={header} />
      </HeaderWrapper>
      <div>{children}</div>
      <Footer data={footer} soeLinks={seolinks} />
      <CookieBanner labels={bannerServiceLabels} showBanner={showBanner} />
    </div>
  )
}
